import profile_pic from './images/profile_pic.png';
import instagram_icon from './images/instagram_icon.png';
import linkedin_icon from './images/linkedin_icon.png';
import twitter_icon from './images/twitter_icon.png';
import mail_icon from './images/mail_icon.png';
import lavender_farm_pic from './images/lavender_farm.png';
import angels_landing_pic from './images/angels_landing.png';
import delicate_arch_pic from './images/delicate_arch.png';
import milford_sound_pic from './images/milford_sound.png';
import roys_peak_pic from './images/roys_peak.png';
import './App.css';
import React, { useState, useEffect, setState, state } from 'react';

const background_color = "rgba(99, 99, 99, 0.463)"; 
const images = [
  [lavender_farm_pic, "NZ Lavender Farm, Jan '23"], [delicate_arch_pic, "Delicate Arch, Jan ‘21", background_color], [milford_sound_pic, "Milford Sound, Dec ‘22", background_color], [roys_peak_pic, "Roy’s Peak, Jan ‘21", ""], /* [angels_landing_pic, "Angel’s Landing, Jan ‘21", ""]*/ ]
;
const randomImg = images[Math.floor(Math.random() * (images.length))]

function App() {
  const [animate, setAnimation] = useState("");

  const handleMouseEnter = () =>{
    setAnimation("wave-animation");
    setTimeout(() => {
      setAnimation("");
    },2500) 
  }

  return (
    <div className="App" style={{backgroundImage: `url(${randomImg[0]})`, backgroundSize: "cover"}}>
      <div className="container">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
        </style>
        <div className="content">
          <div className="profileContainer">
            <div className="profile">
              <img src={profile_pic} className="profilePic" />
              <div className="linkIconContainer">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/michaelzchen/?hl=en"><img src={instagram_icon} className="linkIcon"/></a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/michaelzhongenchen/"><img src={linkedin_icon} className="linkIcon"/></a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/michaelzchen5?lang=en"><img src={twitter_icon} className="linkIcon"/></a>
                <a target="_blank" rel="noopener noreferrer" href="mailto:michaelzchen5@gmail.com"><img src={mail_icon} className="linkIcon"/></a>
              </div>
            </div>
          </div>
          <div className="bioTextContainer">
            <div className="bioText">
              <div onMouseEnter={()=>{handleMouseEnter()}}><span className="headerText">Hi, I'm Michael! </span><span className="headerText wave" style={{animationName: animate}}>👋</span></div>
              <div className="paragraphText">I’m a founder and investor. I dropped out of Harvard briefly to build a healthcare startup delivering at home care. I’ve also worked on a special projects team at <a target="_blank" rel="noopener noreferrer" href="https://www.tesla.com/" className="textLink">Tesla</a>, and angel invested and advised founders at <a target="_blank" rel="noopener noreferrer" href="https://insightpartners.com/" className="textLink">Insight Partners</a> and <a target="_blank" rel="noopener noreferrer" href="https://tribecap.co/" className="textLink">Tribe Capital</a>. Outside of work, I’m an outdoors enthusiast and avid dog lover. </div>
              <div className="subtitleText"><span>I think a lot about… </span><span className="emoji"></span></div>
              <ul className="paragraphText">
                <li>Sustainable development</li>
                <li>Community building</li>
                <li>Techno-optimism</li>
              </ul>
              <div className="subtitleText"><span>I’m currently... </span><span className="emoji"></span></div>
              <ul className="paragraphText">
                <li>Studying applied math and economics at Harvard</li>
                <li>Training for my next marathon (Boston ‘21 was my first)</li>
                <li>Trying to visit every national park in the US (40/63)</li>
                <li>Exploring nature via photography - refresh for more 🍃 </li>
              </ul>
              <div className="paragraphText">You can reach me at <a target="_blank" rel="noopener noreferrer" href="mailto:michaelzchen5@gmail.com" className="textLink">michaelzchen5@gmail.com</a>.</div>
            </div>
          </div>
        </div>
        <div className="imageLabel"><span style={{ backgroundColor: `${randomImg[2]}`, padding: "5px"}}><span>Background photo: </span ><span style={{fontStyle: "italic"}}>{randomImg[1]}</span></span></div>
      </div>
    </div>
    
  );
}

export default App;
